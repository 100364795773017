<template>
      <div id="content" @click="start_choose=false;">
        <div style="margin-left:35rem;margin-top:27rem;"> 
          <img src="@/assets/wap/logo.png" alt="logo" style="width:109rem;height: 21rem;">&nbsp;
        </div>

        <div style="margin: auto;margin-top: 18rem;width: 85%;margin-bottom: 0;"> 
          <img src="@/assets/wap/flag.png" alt="logo" style="width:100%;height: auto;">
        </div>
        <div  style="background-color: #fff;"> 
              <div  v-if="update_pwd==0" style="margin-left:35rem;margin-right:35rem;">
                
                
                  <div style="font-size:16rem;display: flex;justify-content: flex-start;color: #1D2328;cursor: pointer;">
                    <div :style="type==1?'font-weight: 600;color:#000;border-bottom: 2rem solid #000;padding-bottom: 6rem;':'font-weight: 500;border-bottom: 2rem solid #fff;padding-bottom: 6rem;'" @click="type=1;">{{ll[languageId].email}}</div>
                    <div style="margin-left: 20rem;" :style="type==2?'font-weight: 600;color:#000;border-bottom: 2rem solid #000;padding-bottom: 6rem;':'font-weight: 500;border-bottom: 2rem solid #fff;padding-bottom: 6rem;'" @click="type=2;">{{ll[languageId].phone}}</div>
                </div>

              <a-form-model  ref="loginFormModel" :model="userInfo" :rules="rules">
                <a-form-model-item  prop="email" v-if="type==1">
                  <a-input size="large" v-model="userInfo.email"  
                  style="margin-top: 30rem;"  :placeholder="ll[languageId].email_tips" 
                  @change="clearErr" 
                  @keyup.enter="handleLogin"   
                  @focus="handleFocus" @blur="handleBlur"
                  class="custom-input">
                  </a-input>
                </a-form-model-item>
                <!-- <a-form-model-item  prop="phone" v-if="type==2">
                  <a-input size="large" v-model="userInfo.phone"   placeholder="请输入您的手机号" @keyup.enter="handleLogin" class="custom-input">
                  </a-input>
                </a-form-model-item> -->
                <a-form-model-item prop="phone" v-if="type==2">
                  <a-input-group compact style="margin-top: 30rem; display: flex; flex-direction: row;">
                    
                    <div class="areaCode" style="display: flex;cursor: pointer;"  @click.stop="change_start_choose"> 
                        <div>{{userInfo.areaCode ? userInfo.areaCode : ll[languageId].please_choose}}</div>
                        <div v-if="start_choose" class="start_choose">
                            <div v-for="code in areaCodes"    :key="code.areaCode" :value="code.areaCode" @click.stop="changeAreaCode(code)" class="codeItem" style="height: 40rem;line-height: 40rem;">
                              <div style="display: flex; justify-content: space-between; flex-direction: row; align-items: center;">
                                  <div>
                                    <img :src="code.img" alt="flag" style="width: 20rem; height: 14rem; margin-right: 8rem;display: none;" />
                                    <span>{{ code.name }}</span>
                                  </div>
                                  <div>{{ code.areaCode }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a-input  class="custom-input"
                      size="large" 
                      v-model="userInfo.phone" 
                      :placeholder="ll[languageId].phone_tips" 
                      @keyup.enter="handleLogin" 
                      @focus="handleFocus" @blur="handleBlur"
                      @change="clearErr"
                      :style="{ flex: '1' }"
                    />
                  </a-input-group>
                </a-form-model-item>
                <a-form-model-item prop="password" >
                  <a-input-password size="large"   v-model="userInfo.password" :placeholder="ll[languageId].password_tips" 
                  @keyup.enter="handleLogin"
                  @focus="handleFocus" @blur="handleBlur" 
                  @change="clearErr" 
                  class="custom-password-input">
                  </a-input-password>
                  
                </a-form-model-item>
               
                <div style="display: flex;justify-content: space-between;">
                  <div style="font-size:12rem;;padding-top: 0rem;width:40%;color: #38383D;cursor: pointer;" @click.stop="update_pwd=1;">{{ll[languageId].forget_password}}</div>
                  <div v-if="errMessage" style="font-size: 12rem;color:#275B52 ;width:60%;text-align: right;">{{errMessage}}</div>
                </div>
                <a-form-model-item style="margin-bottom: 0;">
                  <a-button size="large" type="primary" style="margin-top:40rem;width:100%;height: 52rem;font-size:16rem;margin-bottom: 0;font-weight: bold;" :style="errMessage? 'opacity: 0.5;cursor: not-allowed;':''"
                   @click.stop="handleLogin" :loading="loadingLogin">{{ll[languageId].login}}</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>

            <div v-if="update_pwd==1" style="margin-left:35rem;margin-right:35rem;position: relative;display: flex;align-items: center;height: auto;flex-direction: column;;  justify-content: center;">
              <div style="font-size: 12rem;color: #626366;margin-top:50rem;">{{ll[languageId].update_password_tips}}</div>
              <a-button size="large" type="primary" style="font-size: 16rem;margin-top:80rem;font-weight: bold;height: 52rem;;width:100%;border:1rem solid #000;color:#000;background-color: #fff;    box-shadow: none;text-shadow: none;"  :loading="loadingLogin"  @click.stop="update_pwd=0;">{{ll[languageId].return_login}}</a-button>
            </div>
        </div>
      </div>
</template>

<script>
// import  storage from 'store'
import { refreshLogin,login,getAreaCode} from '@/api/api'
import { setToken,getLanguage,setLanguage} from '@/api/auth'

export default {
  data(){
    return {
      userInfo:{
        username:"",
        password:"",
        areaCode: '+86' // 默认区号
      },
      areaCodes: [
      ],
   
      loadingLogin:false,
      redirect:'/',
      type:1,
      update_pwd:0,
      errMessage:0,
      languageId:"en",
      ll: {
        "en":{
          "login_account": "Login Account",
          "email": "Email",
          "phone": "Phone",
          "forget_password": "Forget Password",
          "login": "Login",
          "email_error": "Email Error",
          "password_error": "Password require 6-16 digits",
          "email_tips":"Please enter your email",
          "phone_tips":"Please enter your phone",
          "password_tips":"Please enter your password",
          "update_password_tips":"Update password or register on aipaper",
          "return_login":"Back",
          "password_reqired":"Password Reqired",
          "email_reqired":"Email Reqired",
          "phone_reqired":"Phone Reqired",
          "please_choose":"Code",
          "please_choose_area":"Please choose area code",
        },
        "zh":{
          "login_account": "登录账号",
          "email": "邮箱",
          "phone": "手机",
          "forget_password": "忘记密码",
          "login": "登录",
          "email_error": "邮箱格式输入错误",
          "password_error": "请输入6~16位含数字、字母或特殊字符的密码",
          "email_tips":"请输入您的邮箱地址",
          "phone_tips":"请输入您的手机号",
          "password_tips":"请输入6~16位的密码",
          "update_password_tips":"修改密码及注册需在Aipaper pro设备上操作",
          "return_login":"返回登录",
          "password_reqired":"密码必填",
          "email_reqired":"邮箱必填",
          "phone_reqired":"手机号必填",
          "please_choose":"区号",
          "please_choose_area":"请选择区号",
        }
      },
      rules: {},
      start_choose:false,

    }
  },
  mounted(){
    window.scrollTo(0, 0); // 滚动到顶部
    let ll = getLanguage()

    if(! (ll == "" || ll == undefined)) {
      this.languageId = ll
    } 
    console.info("this.languageId",this.languageId)
    setLanguage(this.languageId)

    this.getAreaCodes();
    if (this.$route.query.redirect){
      this.redirect = this.$route.query.redirect
    }
    this.refreshLogins();

    this.rules = {
        email: [{ required: true, message: this.ll[this.languageId].email_reqired, trigger: 'change' }],
        phone: [],
        password: [
          { required: true, message: this.ll[this.languageId].password_reqired, trigger: 'change' }
        ],
      }
  },
 
  watch: {
    type(newValue,oldValue) {
      console.info("type",newValue,oldValue)

      // 先清除现有的验证状态
      this.$refs.loginFormModel.clearValidate(['email', 'phone']);
      this.errMessage = '';
      // 根据type值调整验证规则
      if (newValue == '1') {
        this.userInfo.phone = ''
        this.rules.email = [{ required: true, message: this.ll[this.languageId].email_reqired, trigger: 'change' }];
        this.rules.phone = [];
      } else if (newValue == '2') {
        this.rules.phone = [{ required: true, message: this.ll[this.languageId].phone_reqired, trigger: 'change' }];
        this.rules.email = [];
        this.userInfo.email = ''

      } else {
        this.rules.email = [];
        this.rules.phone = [];
      }
    }
  },

  methods: {
    refreshLogins(){
      refreshLogin({"uri":""}).then(ret => {
            if(ret.data.code == 200) {
              setToken(ret.data.data.token)
              setTimeout(() => {
                this.loadingLogin = false
                this.$router.push({ path: this.redirect })
              }, 500)
            }
          }).catch(() => {
          })
    },
    change_start_choose(){
      this.start_choose = !this.start_choose
    },
    changeAreaCode(newVal) {
      console.info("newValnewVal",newVal);
      this.userInfo.areaCode = newVal.areaCode;
      this.start_choose = false;
      this.clearErr();
      console.info("this.userInfo.areaCode",this.userInfo.areaCode)
    },
    handleFocus(event) {
      this.start_choose = false;
     console.info(event)
      return;
    },
    handleBlur() {
      window.scrollTo(0,0);
    },

    clearErr(){
      this.errMessage = '';
    },
    getAreaCodes(){
      console.info("dddddd")
      getAreaCode({}).then(ret => {
            // storage.set("Access-Token", ret.token, 8 * 60 * 60 * 1000)
            console.info(" ret ret", ret)
            if(ret.data.code == 200) {
              this.areaCodes = ret.data.data
            }
          }).catch(() => {
          })
    },
    
    handleLogin(){
    console.info("this.userInfo",this.userInfo)
      if(this.errMessage) {
        return;
      }
      if(this.userInfo.phone) {
        this.userInfo.email = ''
        if(!this.userInfo.areaCode) {
          return this.$message.error(this.ll[this.languageId].please_choose_area)
        }
      }
      if(this.userInfo.email) {
        this.userInfo.phone = ''
      }
      this.userInfo.source = "2"
      this.$refs.loginFormModel.validate(valid => {
        if (valid) {
          this.loadingLogin = true
          login(this.userInfo).then(ret => {
            // storage.set("Access-Token", ret.token, 8 * 60 * 60 * 1000)
            if(ret.data.code != 200) {
              this.errMessage = ret.data.msg
              this.loadingLogin = false
            }else {
              setToken(ret.data.data.token)
              console.info("ret",ret)
              setTimeout(() => {
                this.loadingLogin = false
                this.$router.push({ path: this.redirect })
              }, 500)
            }
            
          }).catch(() => {
            this.loadingLogin = false
          })
        }
      })
    }
  }
}
</script>
<!-- <style scoped>
.custom-password-input:hover {
  border-color: #40a9ff; /* Change the border color on hover */
  box-shadow: 0 0 8rem rgba(64, 169, 255, 0.6); /* Add a box shadow on hover */
}

.custom-password-input:focus {
  border-color: #1890ff; /* Change the border color on focus */
  box-shadow: 0 0 8rem rgba(24, 144, 255, 0.6); /* Add a box shadow on focus */
  outline: none; /* Remove the default outline */
}
</style> -->

<style>
body {
  font-family: PingFangSC, PingFang SC;
    /* 设置渐变背景从左上角到右下角 */
    /* background: linear-gradient(to bottom right, #E1EDFD, #F3F6FF); */
    background: #fff;
    /* 确保 body 元素填满整个视口 */
    height: 100vh;
    margin: 0;
    padding: 0;
}
.codeItem{
  padding-left: 10rem;
    padding-right: 10rem;
}
.codeItem:hover{
  background: #eee;
}
.start_choose{
  position: absolute;width: 305rem;z-index: 1000;
  background: rgb(255, 255, 255);
  border: 1rem solid rgb(217, 217, 217);top: 51rem;left: -1rem;
  border-top:none;
  max-height: 300rem;overflow-y: auto;
  cursor: pointer;
}
.areaCode{
  width: 25%;border:1rem solid #d9d9d9;position: relative;    display: flex;
    align-items: center;
    justify-content: center;background-color: #F9FAF9;
   
}
.areaCode:hover {
  background-color: #fff;
  border-color:#DBDBDB;
  box-shadow: 0 0 8rem#DBDBDB;
}
#content{
  position:absolute;
  border:2rem;
  border-radius:4rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: 100rem;
}
.ant-select-lg .ant-select-selection--single{
  height: 52rem;
}
.ant-select-lg .ant-select-selection__rendered {
    line-height: 52rem;
}
.ant-select-arrow .ant-select-arrow-icon svg{
  color:rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.ant-select-selection {
  background-color: #FAFBFD;
  font-size: 14rem;
  height: 52rem;;
  border: 0;
  overflow: hidden;
}
.ant-select-selection:hover {
  background-color: #fff;
  border-color:#DBDBDB;
  box-shadow: 0 0 8rem#DBDBDB;
}
.ant-select-selection:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem #DBDBDB;
}
.custom-input {
  font-size: 14rem;
  background: #F9FAF9;
  border-radius: 2rem;
  height: 52rem;;
}
.custom-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem #DBDBDB;
}
.custom-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem #DBDBDB;
}
.custom-password-input  {
  background-color: #F9FAF9;
  font-size: 14rem;
  height: 52rem;;
  border-radius: 2rem;

}

.ant-input-affix-wrapper .ant-input {
    position: relative;
    text-align: inherit;
    border:none;
    height: 100%;
    font-size: 14rem;
    background: #F9FAF9;
    border: 1rem solid #d9d9d9;
    border-radius: 2rem;

}
.custom-password-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem  #DBDBDB;
}
.custom-password-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem  #DBDBDB;
}

.ant-input-affix-wrapper .ant-input:not(:last-child):hover{
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem  #DBDBDB;
  border: 1rem solid #d9d9d9;

}
.ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem  #DBDBDB;
}
.ant-input-affix-wrapper .ant-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8rem  #DBDBDB;
}
.ant-form-explain{
  font-size: 12rem;
}
.ant-btn-primary{
  background-color: #000;
  border-color:#000;
  border-radius: 2rem;
}
.ant-btn-primary:hover{
  background-color: #000;
  border-color:#000
}
.ant-btn-primary:focus{
  background-color: #000;
  border-color:#000
}


</style>